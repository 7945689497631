<template>
  <!-- :class="{
      personal_center1: typeFour,
    }" -->
  <div class="personal_center" v-if="typeOne">
    <div class="center_profile">
      <div class="profile">
        <div class="profile_left">
          <div class="profile_img">
            <el-image class="userImg" :src="detail.img"></el-image>
          </div>
        </div>
        <div class="profile_right">
          <div class="right_title">{{ detail.nickName }}</div>
          <div class="right_tip">
            {{
              detail.autograph ? detail.autograph : "这个人很懒，什么都没有留下"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="center_nav">
      <div class="nav_center">
        <div class="userCentLefts" v-for="(item, index) in list" :key="index">
          <div
            class="userCentLeftsC"
            v-for="(chil, chilIndex) in item.chil"
            :key="chilIndex"
            :class="[tabId == chil.id ? 'on' : '']"
          >
            <div
              :class="[tabId == chil.id ? 'ssk' : '']"
              class="userCentLeftsCr"
              @click.stop="tabChange(chil)"
            >
              <i
                class="iconfont"
                :class="[tabId == chil.id ? 'ccc' : '', chil.icon]"
              />{{ chil.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center_centent">
      <div class="userCent">
        <!-- 右侧盒子 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo, getDomain } from "@/api/cookies";
import { getUserCourseStatistics } from "@/api/user";
export default {
  components: {},

  data() {
    return {
      m: "",
      tel: "",
      uid: "",
      userId: "",
      list: [
        {
          title: "个人主页",
          chil: [
            {
              id: 2,
              title: "个人资料",
              url: "/my/myInfo",
              icon: "icon-bianjigerenziliao",
            },
          ],
        },
        {
          title: "订单管理",
          chil: [
            {
              id: 3,
              title: "我的订单",
              url: "/my/mineOrder",
              icon: "icon-a-Group1530",
            },
            {
              id: 4,
              title: "优惠券",
              url: "/my/mineCoupon",
              icon: "icon-youhuiquan1",
            },
            {
              id: 6,
              title: "兑换券",
              url: "/my/mineExchange",
              icon: "icon-youhuiquan1",
            },
          ],
        },
        {
          title: "学习卡",
          chil: [
            {
              id: 9,
              title: "学习卡",
              url: "/my/myCard",
              icon: "icon-xuexika",
            },
          ],
        },
        {
          title: "我的提问",
          chil: [
            {
              id: 10,
              title: "我的提问",
              url: "/my/myAskQuestions",
              icon: "icon-tiwen",
            },
          ],
        },
        {
          title: "意见反馈",
          chil: [
            {
              id: 7,
              title: "意见反馈",
              url: "/my/feedback",
              icon: "icon-yijianfankui1",
            },
          ],
        },
        {
          title: "账户设置",
          chil: [
            {
              id: 8,
              title: "账户设置",
              url: "/my/accountSetting",
              icon: "icon-zhanghushezhi",
            },
          ],
        },
        {
          title: "消息管理",
          chil: [
            {
              id: 5,
              title: "我的消息",
              url: "/my/mineNews",
              icon: "icon-a-Group1533",
            },
          ],
        },
        {
          title: "我的收藏",
          chil: [
            {
              id: 11,
              title: "我的收藏",
              url: "/my/collect",
              icon: "icon-shoucangxing",
            },
          ],
        },
        // {
        //   title: "消息管理",
        //   chil: [
        //     {
        //       id: 5,
        //       title: "我的成就",
        //       url: "/myAchievement",
        //       icon: "icon-a-Group1533",
        //     },
        //   ],
        // },
      ],
      detail: {
        img: "",
        nickName: "",
      },
      tabId: 1,
      chong: {},
      typeFour: false,
      typeOne: false,
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == "/login" || newVal.path == "/login") {
        this.userId = localStorage.getItem("userId");
      }
    },
  },
  created() {
    this.userId = localStorage.getItem("userId");
    if (!this.userId) {
      Vue.prototype.goLoginView("mustBack");
      return;
    }
    for (let item of this.list) {
      for (let chil of item.chil) {
        if (this.$route.path == chil.url) {
          this.tabId = chil.id;
          break;
        }
      }
    }

    this.detail = getInfo();
    // 个人中心 顶部数据
    getUserCourseStatistics(this.userId).then((res) => {
      this.chong = res.msg;
    });

    //滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        let top = $(window).scrollTop();
        if (top >= 290) {
          $(".userCentLeft").addClass("fixed-card");
          $(".zhicheng").removeClass("dis-none");
        } else {
          $(".userCentLeft").removeClass("fixed-card");
          $(".zhicheng").addClass("dis-none");
        }
      });
    });
    const res = getDomain();
    if (res.entrantsType === 4) {
      this.typeFour = true;
      this.typeOne = false;
    } else if (res.entrantsType === 2) {
      this.typeFour = false;
      this.typeOne = true;
    }
  },
  methods: {
    tabChange(val) {
      if (this.tabId == val.id) {
        return;
      }
      this.titles = val.title;
      this.tabId = val.id;
      this.$router.replace(val.url);
    },
  },
};
</script>
<style lang="less" scoped>
.personal_center {
  width: 100%;
  // padding-bottom: 160px;
  .center_profile {
    width: 100%;
    height: 160px;
    background-image: url("~@/assets/img/user/Group 1519.png");
    background-size: cover;
    display: flex;
    align-items: center;
    .profile {
      display: flex;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
      .profile_left {
        width: 80px;
        height: 80px;
        opacity: 1;
        border: 2px solid #ffffff;
        border-radius: 50%;
        margin-right: 16px;
        .profile_img {
          width: 100%;
          height: 100%;
          /deep/ .el-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
      .profile_right {
        .right_title {
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333;
        }
        .right_tip {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 20px;
        }
      }
    }
  }
  .center_nav {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .nav_center {
      width: 1220px;
      margin: 0 auto;
      // display: flex;
      .userCentLefts {
        float: left;
        /* margin-right: 80px; */
        .userCentLeftsC {
          float: left;
          margin-right: 34px;
          .userCentLeftsCr {
            cursor: pointer;
            padding: 18px 0;
            .iconfont {
              font-size: 16px;
              margin-right: 8px;
              flex-shrink: 0;
            }
            // border-bottom: 1px solid #ff5e51;
          }
          .ssk {
            color: #ff5d51;
            border-bottom: 1px solid #ff5e51;
          }
        }
      }
      :last-child .userCentLeftsC {
        margin-right: 0;
      }
    }
  }
  .center_centent {
    width: 1221px;
    background: #ffffff;
    margin: 20px auto 30px;
    .userCent {
      width: 100%;
      height: auto;
      margin: auto;
      // margin-bottom: 100px;
    }
  }
}
.personal_center1 {
  padding-bottom: 250px;
}
</style>
